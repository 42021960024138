<template>

  <div class="page-content">
    <div class="container text-center">
      <div class="display-1 text-muted mb-5"><i class="si si-exclamation"></i> Not allowed</div>
      <h1 class="h2 mb-5">You do not have permission to view this page</h1>
      <a
        class="btn btn-primary"
        tabindex="0"
        href="/welcome">
        <i class="fe fe-arrow-left mr-2"></i>Go home
      </a>
      <router-link class="btn btn-primary ml-2" to="/logout">Sign out</router-link>
    </div>
  </div>

</template>

<script>

export default {
  mounted() {
    setTimeout(() => {
      window.location.href = '/welcome';
    }, 10_000);
  },
};

</script>
